

























































import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'
import { EditJob, DownloadManifest, ActionCategory, EditJobStatus, ImageStatus, ImageEntry } from '@ht-lib/accounts-models'
import { UploadButton } from '@ht-vue/image-transfer'
import { findFile, UploadImage, batchMergeImages, PartialImageWithLongRef } from '@ht-lib/image-util'
import { updateEditJob } from '../ts/editing'
import { getUnixTime } from 'date-fns'
import { uploader } from '../upload'
import Notify from 'quasar/src/plugins/Notify.js';

enum UnFlagSetting {
  ALL = 'All Images In Edit Job',
  UPLOADED = 'All Uploaded Images',
  NONE = 'None'
}

@Component({
  name: 'UploadModal',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  components: { UploadButton }
})
export default class UploadModal extends Vue {
  @Prop({
    required: true
  }) job: EditJob

  approved = false
  manifest: DownloadManifest = null
  files: File[] = []
  uploadImages: UploadImage[] = []
  actionNotes = ''
  actionCategory = ActionCategory.OTHER
  categoryOptions = Object.values(ActionCategory)
  uploaded = false
  unflagAllImages: UnFlagSetting = UnFlagSetting.ALL
  unflagSettings = Object.values(UnFlagSetting)

  created () {
    this.actionNotes = this.job.actionNotes
  }

  clear () {
    this.manifest = null
    this.files = []
  }

  async finish (completeJob: boolean) {
    const update: Partial<EditJob> = {
      actionNotes: this.actionNotes || '',
      actionCategory: this.actionCategory,
      completeTime: getUnixTime(new Date())
    }

    if (completeJob) {
      update.status = EditJobStatus.COMPLETE
    }

    await updateEditJob(this.job.id, update)
  }

  async onFinishUpload () {
    let updates: PartialImageWithLongRef[]

    switch (this.unflagAllImages) {
      case UnFlagSetting.NONE: {
        break
      }
      case UnFlagSetting.ALL: {
        updates = this.manifest.longRefPairs.map(img => ({
          longRef: img.longRef,
          status: ImageStatus.NONE
        }))
        break
      }
      case UnFlagSetting.UPLOADED: {
        updates = this.uploadImages.map(img => ({
          longRef: img.longRef,
          status: ImageStatus.NONE
        }))
        break
      }
    }
    if (updates) {
      await batchMergeImages(updates)
    }
    this.uploaded = true
  }

  upload () {
    uploader.addUploadJob({
      description: this.job.title,
      id: this.job.id,
      uploaderId: this.$auth.user.uid,
      images: this.uploadImages,
      onFinish: async () => { await this.onFinishUpload() },
      meta: undefined
    })
  }

  fileExists (name: string) {
    return !!findFile(name, this.files)
  }

  get contextsMatch () {
    return this.manifest?.contextId === this.job.id
  }

  async filesSelected (files: File[]) {
    const manifestFile = findFile('manifest', files)
    if (!manifestFile) {
      Notify.create({
        message: 'Could not find the download manifest. Please make sure you\'re selecting the right folder, which should contain the download manifest.',
        timeout: 0,
        actions: [{
          label: 'Dismiss', color: 'white', handler: () => { console.log('dismissed') }
        }],
        type: 'negative'
      })
      return
    }

    files.splice(files.indexOf(manifestFile), 1)
    this.files = files
    this.manifest = JSON.parse(await manifestFile.text())

    this.uploadImages = this.files.map((file) => {
      const pair = this.manifest.longRefPairs.find(p => p.fileName.toUpperCase() === file.name.toUpperCase())
      if (!pair) {
        Notify.create({
          message: `File: ${ file.name } was found in the folder but could not be found in the download manifest. This file is not going to be uploaded`,
          timeout: 0,
          actions: [{
            label: 'Dismiss', color: 'white', handler: () => { console.log('dismissed') }
          }],
          type: 'negative'
        })
        return null
      }

      return {
        longRef: pair.longRef,
        file: file,
        uploaded: false
      }
    })
  }
}
